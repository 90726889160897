import React from "react";
import Helmet from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"

class errorPage extends React.Component {
  // The entire calculator pages use the same template and have the same basic authorization.
  render() {
    return (
      <LayoutMain className="404">
        <Helmet>
          <title>Blue Tenon</title>
          <meta
            name="description"
            content="Page not found"
          />
        </Helmet>

        <div className="container-404">
          <div className="section-main">
            <h3>You have found a page that does not exist.</h3>
          </div>
        </div>

      </LayoutMain>
    )
  }
}
export default errorPage;